* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.navbar {
  background: #2f2626;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 999;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  height: 80px;
}

.container {
  z-index: 1;
  width: 100%;
  max-width: 1300px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 50px;
  padding-left: 50px;
}

.navbar-logo {
  color: #fff;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
}

.navbar-icon {
  margin-right: 0.5rem;
}

.nav-menu {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  text-align: center;
  justify-content: flex-end;
}

.nav-item {
  height: 80px;
  border-bottom: 2px solid transparent;
}

.nav-item:hover {
  border-bottom: 2px solid #c5aa6a;
}

.nav-links {
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
}

.activated {
  color: #c5aa6a;
}

.fa-bars {
  color: #fff;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #2f2626;
    left: 0;
    opacity: 1;
    transition: all 0.6s ease;
    z-index: 1;
    width: 100%;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    color: #c5aa6a;
    transform: scale(1.2);
    transition: all 0.3s ease;
  }

  .nav-item:hover {
    border: none;
  }

  .nav-item {
    width: 100%;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 50%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  @media screen and (max-width: 768px) {
    .navbar-container {
      padding-right: 20px;
      padding-left: 20px;
    }

    .navbar-logo {
      font-size: 1.5rem;
      position: absolute;
      top: 30%;
      left: 50%;
      transform: translateX(-50%);
    }

    .menu-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
    }

    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      position: absolute;
      top: 80px;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
      background-color: #2f2626;
      z-index: 1;
    }

    .nav-menu.active {
      left: 0;
      opacity: 1;
      transition: all 0.6s ease;
    }

    .nav-links {
      text-align: center;
      padding: 1.5rem;
      width: 100%;
      display: table;
    }

    .nav-item {
      width: 100%;
      border: none;
    }
  }
}
