
.cv-button {
  background-color: #2f2626; /* Black background */
  color: #fff; /* White text color */
  border: none;
  padding: 13px 9px;
  margin-top: 10px;
  cursor: pointer;
  text-decoration: none; /* Remove underline for anchor inside the button */
  display: inline-block;
  border-radius: 8px;
  overflow: hidden;
}

h1
{
  text-align: center;
  color: black;
}

.cv-button:hover {
  animation: rainbowEffect 2s infinite; /* Apply the rainbow animation on hover */
}

.cv-button a {
  color: #fff; /* Set text color for the anchor */
  text-decoration: none; /* Remove underline for the anchor */
}

@keyframes rainbowEffect {
  0% {
    box-shadow: 0 0 10px 5px rgb(255, 0, 0); /* Red shadow */
  }
  16.666% {
    box-shadow: 0 0 10px 5px rgb(255, 166, 0); /* Orange shadow */
  }
  33.333% {
    box-shadow: 0 0 10px 5px rgb(255, 255, 0); /* Yellow shadow */
  }
  50% {
    box-shadow: 0 0 10px 5px rgba(0, 255, 0, 0.5); /* Green shadow */
  }
  66.666% {
    box-shadow: 0 0 10px 5px rgb(0, 0, 255); /* Blue shadow */
  }
  83.333% {
    box-shadow: 0 0 10px 5px rgb(76, 0, 130); /* Indigo shadow */
  }
  100% {
    box-shadow: 0 0 10px 5px rgb(255, 180, 228); /* Violet shadow */
  }
}
